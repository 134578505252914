@media only screen and (max-width: 500px) {
  .hero-2col>div>div>div.sec-left>div {
    background-size: contain !important;
    height: 250px !important;
    // margin-top: 100px;
    margin-top: 20px ;
    // margin-top: -150px !important;
    // margin-bottom: -120px !important;
  }
}

.success-superparent {
  width: 100svw;
  padding: 50px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  h2.head {
    font-size: 40px;
  }

  background: var(--grey);

  .success-swipe {
    --height: 500px;
    height: var(var(--height));

    swiper {
      width: calc((var(--height) * 4) / 5);
      height: var(--height);
    }

    .swiper-pagination-bullet {
      background: var(--white);
      height: 10px;
      width: 10px;
      border-radius: 10px;
      transition: var(--trans);
    }

    .swiper-pagination-bullet-active {
      background: var(--accent);
      width: 40px;
      height: 10px;
      border-radius: 10px;
    }

    .swiper-slide {
      height: var(--height);
      // background: url("../assets/home/s1.jpg");
      line-height: var(--height);
      text-align: center;
      background-position: bottom center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      border-radius: var(--rad);
      overflow: hidden;

      .overlay {
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgb(0, 0, 0), transparent);
        position: relative;
        overflow: hidden;

        &:hover {
          h2 {
            bottom: 30%;
          }

          .btn2 {
            top: 80%;
          }
        }

        h2 {
          color: var(--white);
          transition: var(--trans);
          position: absolute;
          bottom: 5%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 30px;
        }

        .btn2 {
          position: absolute;
          top: 150%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: var(--white);
          padding: 10px 20px;
          border: 2px solid var(--white);
          line-height: 100%;
          transition: var(--trans);

          &:hover {
            border: 2px solid var(--accent);
            background: var(--accent);
            color: var(--white);
          }
        }
      }
    }
  }
}

.blog-parent1 {
  height: auto;
  padding: 50px 0px;

  .blog-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;

    .blog-headding {
      width: 100%;
      text-align: center;
    }

    .blog-card {
      width: 300px;
      height: auto;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      // box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.318);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18);
      overflow: hidden;

      .blog-card-img {
        width: 300px;
        height: 200px;
        position: relative;

        .blog-img {
          width: 100%;
          height: 100%;
          object-fit: cover !important;
        }

        .blogdate {
          position: absolute;
          top: 10px;
          left: 67%;
          background: var(--white);
          border-radius: 5px;
          padding: 5px;
        }
      }

      .blog-card-bottom {
        display: flex;
        gap: 10px;
        flex-direction: column;
        padding: 10px;
        align-items: center;
        justify-content: center;

        .blog-title {
          text-align: center;
          color: black;
          font-size: 22px;
          font-weight: 300;
        }

        .blog-text {}
      }
    }
  }

  .loadmore-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px 0px 0px;
    flex-direction: column;

    .loadmore {}
  }
}

.box {
  height: 800px;
  padding-top: 100px;
}

.accr {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin: 10px auto;

  .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(195, 195, 195);
    padding: 0px 10px;
    border-radius: 5px;
    user-select: none;
  }

  .btm {
    width: 100%;
    background: grey;
    padding: 20px 10px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .head {
    font-size: 30px !important;
  }

}