.health-parent {
  width: 100%;
  // height:100vh;

  // .img-text {
  //   background: url("../photos/Akka_foundation/background-home.webp");
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center center;
  //   width: 100%;
  //   height: 600px;
  //   position: relative;
  

  //   .text-wrapper 
  //   {
  //       width: 100%;
  //       height: 100%;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: flex-start;
  //       justify-content: flex-start;
  //       gap: 20px;

  //       .text-wrapper-inner 
  //       {
  //           height: 100%;
  //           width: 50%;
  //           flex-direction: column;
  //           display: flex;
  //           align-items: flex-start;
  //           justify-content: center;
  //           gap: 20px;
  //       }
  //   }
  //   .t-desc {
  //     z-index: 1;
      
      
  //     color:var(--white);
  //   }
  //   .btn {
  //       z-index: 1;
      
  //   }
  // }
  // @media only screen and (max-width:1000px){
  //   .img-text{
  //     background:url("../photos/Akka_foundation/background-home\ -\ Copy.jpg") !important;
  //     .text-wrapper{
  //       display:flex;
  //       align-items: center;
  //       justify-content: center;
  //       .text-wrapper-inner{
  //         text-align: center;
  //         width:100%;
  //         justify-content: center;
        
  //       }
  //     }
  //     .t-desc{
  //       line-height: 30px !important;
  //      }
  //   }
  // }
  // .overlay {
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   background: rgba(0, 0, 0, 0.144);
  // }
  // @media only screen and (max-width:1000px){
  //   .overlay{
  //     background: rgba(0, 0, 0, 0.452);
  //   }
  // }

  .health-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding:50px 10px;
    gap:20px;
    .left {
      width: 50%;
      background: url("../photos/Akka_foundation/inner\ image.jpg");
      height: 350px;
      border-radius: 10px;
    }
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap:10px;
    
      justify-content: center;
      .icon{
        display:flex;
        align-items: center;
        // justify-content: center;
        border:4px solid var(--accent);
        width:fit-content;
        border-radius: 100px;
        padding:5px 10px;
        color:var(--white);
        background:var(--accent);
        gap:10px;
        // font-size:26px;
        
        &:hover{
            color:var(--accent);
            background:var(--white);
            border:4px solid var(--accent);
        }
    }
    }
  }
  @media only screen and (max-width:1000px){
    .health-cont{
      flex-direction: column;
      .left{
        width: 100%;
      }
      .right{
        width: 100%;
        align-items: center;
      }
    }
  }
}
