.photo-parent {
    // background-color: #efefefde;
    height: auto;
    width: 100%;
    // margin-top: 70px;
    // padding: 50px 10px;
    .gallery-c {

        
        max-width: var(--max-width);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

       
        

    }
}


.image-card {
    border-radius: 10px;

    margin-top: 70px;
    width: 350px;
    height: 250px;
    object-fit: cover;
    margin: 15px;
}

#lightbox-img {
    margin-top: 40px;
    height: 70vh;
    max-width: 80vw;
    object-fit: cover;
}

#lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //   background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;

}




img:hover,
.gallery-btn:hover {
    cursor: pointer;

}


#lightbox 
{
    background:  #0000006e !important;
}