.manifesto {
    height: auto;
    padding: 80px 0px;
   

    .manifesto-cont {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .mcontent {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;

            .card {
                height: 500px;
                width: 350px;
                border-radius: 20px;
                box-shadow: var(--shadow);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                background-color: #fff;

                .book-placeholder {
                    box-shadow: var(--shadow);
                    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
                    transform-origin: left;  
                    
                    
                    &:hover {
                        transform: perspective(1000px) rotateY(-10deg); // Turn from the right side
                        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); // Stronger shadow during the tilt
                    }
                }

                img {
                    height: 300px;
                    width: 350px;
                }

                .social {
                    display: flex;
                    gap: 10px;

                    a {
                        font-size: 20px;
                        color: var(--accent);

                        &:hover {
                            color: #111;
                        }
                    }
                }
                button{
                    font-weight: 700;
                }
            }
        }
    }
}

//  Iframe Overlay Styles */
.iframe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    iframe {
        border: none;
        border-radius: 0px;
    }

    .close-button {
        position: absolute;
        left: 80px;
        bottom: 150px;
        margin-bottom: 20px;
        padding: 20px 62px;
        background-color: white;
        color: black;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-size: 18px;
        font-weight: 500;

        &:hover {
            background-color: var(--accent);
            color: #fff;
        }
    }
}

@media only screen and (max-width:780px) {
    .mcontent {
        display: flex !important;
        flex-direction: column !important;
        padding: 0px 20px;
    }
    .close-button{
        position: absolute !important;
        bottom: 10px !important;    
        left:150px !important;      
        margin-top: 20px !important;
        padding: 10px 20px !important;
        background-color: white !important;
        color: black !important;
        border: none !important;
        border-radius: 5px !important;
        cursor: pointer !important;
    }
}
