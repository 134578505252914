.jal-parent {
  height: 600px;
  width: 100%;
  background: url("../assets/jal.jpeg");
  position: relative;
  display: flex;
  justify-content: center;

  .jal-overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #000000b4, #00000000);
    position: absolute;
  }

  .jal-headding1 {
    position: absolute;
    z-index: 2;
    color: white;
    padding-left: 30px;
    bottom: 20px;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
}

.jal2-parent {
  height: 1000px;
  width: 100%;
  // background: url('../assets/jal3.jpg');
  position: relative;
  display: flex;
   justify-content: center;
  align-items: center;
// background-color: black;

.bg-vid{
  height: 80%;
  width: 100%;
  position: absolute;
  top: 0;
}
  .jal2-overlay {
    height: 80%;
    width: 100%;
    top: 0;
    // background: linear-gradient(90deg,
    //         rgba(0, 0, 0, 0.71) 50%,
    //         rgba(0, 0, 0, 0.184));
    background-color: rgba(0, 0, 0, 0.575);
    z-index: 1;
    position: absolute;
  }

  .jal2-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    z-index: 5;
    position: absolute;
    justify-content: center;
    align-items: center;
    // background-color: var(--accent);
   bottom: 30px;
    gap: var(--gap);
    padding: 20px;
    border-radius: var(--rad);
    backdrop-filter: blur(16px) saturate(180%);
    // -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    // background-color: #a81c1cbb;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);

    .jal-headding {
      font-size: 30px;
    }

    .jal2-text,
    .jal-headding {
      color: rgb(255, 255, 255);


    }

    p {
      color: white;

    }

    .read-more {
      color: white;
      font-weight: 700;
      background-color: var(--accent);
      padding: 0px 5px;

    }


  }
}

.img-box-jal {
  background: url("../photos/Jal_saksharta_rally/Jal_Saksharta_Rally.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  aspect-ratio: 5/4;
  padding: 20px;
  border-radius: 10px;
}

.counter-parent2 {
  padding: 40px 0px;
  // width: 50%;
  // margin: 0px auto;


  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin: auto;

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
      background: var(--accent);
    }



    .swiper-button-prev:after,
    .swiper-button-next:after {
      display: none;
    }
  }
}

@media only screen and (max-width: 750px) {
  .jal2-parent {
    background: url("../photos//Jal_saksharta_rally/for\ mobile\ view.webp") !important;
    position: relative !important;
    height: 100vh !important;
    background-size: cover !important;
    background-position: center !important;



    &.tass-mobile-img {
      background: url("../photos/72_taas_andolan/72tass_mobile_view.webp") !important;
      position: relative !important;
      height: 100vh !important;
      background-size: cover !important;
      background-position: center center !important;
    }

    &.factory-mobile-img {
      background: url("../photos/coach_factory/factory_mobile_view.webp") !important;
      position: relative !important;
      height: 100vh !important;
      background-size: cover !important;
      background-position: center center !important;
    }

    &.jan-jagar-mobile-img {
      background: url("../photos/jan_jagar_sanwad/sanwad_mobile_view.webp") !important;
      position: relative !important;
      height: 100vh !important;
      background-size: cover !important;
      background-position: center center !important;
    }

    &.atal-maharogya-mobile-img {
      background: url("../photos/Atal_Mahaarogya_Shibir/maharogya_mobile_view.webp") !important;
      position: relative !important;
      height: 100vh !important;
      background-size: cover !important;
      background-position: center center !important;
    }

    &.namo-maharojgar-mobile-img {
      background: url("../photos/personality/bjp.jpg") !important;
      position: relative !important;
      height: 100vh !important;
      background-size: cover !important;
      background-position: center center !important;
    }

    &.indraprasta-mobile-img {
      background: url("../photos/indraprasta/indraprasta_mobile_img.webp") !important;
      position: relative !important;
      height: 100vh !important;
      background-size: cover !important;
      background-position: center center !important;
    }

    video {
      display: none !important;
    }

    .jal2-content {
      position: relative !important;
      top: 0 !important;
      width: 100% !important;
      height: 100vh !important;
      background-color: transparent !important;
      backdrop-filter: none !important;

      display: flex;
      justify-content: flex-end !important;



      h2 {
        font-size: 24px !important;
        font-weight: 900 !important;
      }

      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }


}

.box-counter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: var(--shadow);
  border-radius: 10px;
  gap: 20px;
  width: fit-content;

  .icon {
    height: 50px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aqua;
    border-radius: 15px;
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    flex-direction: column;

    h2,
    p {
      line-height: 100%;
      color: rgba(255, 255, 255, 0.514);
    }
  }
}


@media only screen and (max-width:750px){
  .jal-parent {
    .jal-overlay {

    }

    .jal-headding1 {
      padding-left: 0px !important;
      font-size: 35px !important;
    }
  }

.jal2-parent{
  .jal2-overlay{
    height: 100% !important;
  }
}

}