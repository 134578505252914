.toolbox-wrapper 
{
    position: fixed;
    right: 17px;
    bottom: 5%;
    z-index: 9000;
    display: flex;
    justify-content: center;
    align-items: center;

    button 
    {
       
        font-size: 20px;
        padding: 4px;
        cursor: pointer;
        color: var(--black);
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
        
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background: var(--accent);
        border: none;

        .img{

            background: url('../assets/toggle-btn.png');
            height: 100%;
            width: 100%;
        }

        &:hover 
        {
            transform: translateY(-5px);
        }
    }
}