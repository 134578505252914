.gallery-buttons-swiper {
    display: flex;
    padding: 50px 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    display: none !important;

    .container-small-swiper {
        width: 100%;
        margin: auto !important;

        .mySwiper {
            width: 100%;
        }

        .swiper-btns {
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
}




.gallery-buttons {
    // margin-top: 70px;
    width: 100%;
    padding: 50px 10px;


    .container-small-swiper {

        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .mySwiper {
            width: 100%;
            height: 100%;
        }

        .swiper-btns {

            margin-left: auto !important;
            margin-right: auto !important;
        }
    }




    // padding: 20px !important;
    .gallery-btn-div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 80px;



        .gallery-btn1 {
            border: none;
            font-weight: 300 !important;

        }
    }
}

.gallery-btn-new {
    cursor: pointer;
    border: 1px solid var(--accent) !important;
    font-weight: 300 !important;
    color: var(--accent) !important;
    font-size: 18px;
    padding: 5px 20px;
    border-radius: 200px;
    text-decoration: none;
    transition: var(--trans);
    width: fit-content;
    background: rgb(240, 240, 240);

}

.gallery-container {
    padding: 0px !important;

    .gallery-inner-div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }
}

.gallery-btn {
    text-decoration: none;
    color: var(--black);

    cursor: pointer;

    padding: 5px 5px;
    border-radius: 100px;
    transition: var(--trans2);
    display: flex;
    align-items: center;
    width: fit-content;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
        background: linear-gradient(90deg, var(--darkorange), red);
        transform: scale(1.05);
        transition: var(--trans);
        color: white;
    }
}

// @media only screen and (max-width: 550px) {
//     .gallery-buttons {
//         margin-top: 0px !important;
//         .gallery-btn-div {
//             flex-direction: column !important;
//             gap: 10px !important;


//         }
//     }
// }

@media only screen and (max-width: 700px) {
    .gallery-buttons {

        .gallery-btn-div {

            gap: 20px;
        }
    }
}

@media only screen and (max-width: 550px) {
    .gallery-buttons-swiper {

        display: block !important;
    }


    .gallery-buttons {
        display: none !important;

        .gallery-btn-div {}
    }
}