.indraprasta-parent {
    height: 600px;
    width: 100%; 
    background: url("../assets/Indraprashta_jalbhumi_Abhiyan.jpg");
    position: relative;
    display: flex;
    justify-content: center;
  
    .indraprasta-overlay {
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, #000000b4, #00000000);
      position: absolute;
    }
  
    .indraprasta-headding1 {
      position: absolute;
      z-index: 2;
      color: white;
      padding-left: 30px;
      bottom: 20px;
      font-size: 45px;
      font-weight: 700;
      text-align: center;
    }
  }