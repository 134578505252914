.contact2 {
  // margin-top: 60px;
  // min-height: calc(100svh - 60px);
  // padding: 50px 0px;
  padding-bottom: 50px;

  .contact2-container {
    display: flex;
    flex-direction: column;

    .contact2-cont-top {
      min-height: 200px;
      // border: 1px solid rebeccapurple;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: calc(var(--gap) / 2);

      p {
        text-align: center;
        max-width: 65ch;
      }
    }

    .contact2-cont-bottom {
      height: 100%;
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: var(--gap);

      .contact2-cont-bottom-left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: calc(var(--gap) / 2);
          width: 100%;

          .input-div {
            width: 100%;
            display: flex;
            align-items: center;
            gap: calc(var(--gap) / 2);
          }

          input,
          textarea {
            padding: 10px 20px;
            border-radius: calc(var(--rad) / 2);
            border: none;
            outline: none;
            border: 1px solid transparent;
            transition: var(--trans);
            background: rgb(245, 245, 245);
            width: 100%;

            &:focus {
              border: 1px solid var(--accent);
            }
          }
        }
      }

      .contact2-cont-bottom-right {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 0px;

        .contacts-list {
          width: 450px;
          padding: 0px;
          display: flex;
          flex-direction: column;
          gap: var(--gap);

          .contact-item {
            width: 100%;
            background: rgb(245, 245, 245);
            border-radius: calc(var(--rad) / 2);
            padding: 15px;
            display: flex;
            gap: calc(var(--gap)/2);
            align-items: flex-start;

            .icons {
              width: 60px;
              aspect-ratio: 1 !important;
              background: var(--accent);
              border-radius: calc(var(--rad) / 2);
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--white);
              font-size: 22px;
            }

            h4 {
              color: var(--black);
            }

            .item-details {

              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-wrap: wrap !important;
            }
          }

        }

        width: 50%;
      }
    }
  }
}


@media only screen and (max-width:1200px) {

  .contact2-cont-bottom-right {
    width: 450px !important;
  }

  .contact2-cont-bottom-left {
    width: calc(100% - 470px) !important;
  }

}

@media only screen and (max-width:1000px) {


  .contact2-cont-bottom {
    flex-direction: column !important;

    .contact2-cont-bottom-right,
    .contact2-cont-bottom-left {
      width: 100% !important;
    }
  }
}


@media only screen and (max-width:550px) {
  .contact2 {
    margin-top: 0px !important;

    .btn-new{
      font-weight: 300 !important;
    }
  }
}


@media only screen and (max-width:400px) {
  .contact2-cont-bottom-right {
    padding: 0px;

    .contacts-list {
      width: 350px !important;
      padding: 0px;

      .contact-item {
        width: 100% !important;

        .contact-p {
          font-size: 14px !important;
        }
      }
    }
  }
}