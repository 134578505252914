.modal-parent {
  height: 100vh;
  width: 100%;
  z-index: 80;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.637);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;

  .pop-box {
    // width: 100%;
    background-color: #ffffffd3;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.225);
    // margin-bottom: 30px;
    @media only screen and (max-width:600px){
        width:100%;
    }


    .close-box 
    {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .close {
            // border: 1px solid red;
        
              height: 40px;
              width: 40px;
              background: var(--accent);
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              cursor: pointer;
            }
    }
   

    .contact-right-model {
      width: 100%;
      height: fit-content;
    //   padding: 0px !important;
      gap: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    //   padding: 26px;
      .first-sec {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
        gap: 12px;
        // border: 1px solid red;
        .contact-headding {
          font-size: 35px;
          font-weight: 800;
          
          text-transform: capitalize;
        
          line-height: 100%;
        }
        .desc-p {
        //   width: 50ch;
          text-align: center;
          line-height: 100%;
         
        }
      }
      @media only screen and (max-width: 600px) {
       
           .first-sec{
               .contact-headding{
                   font-size: 22px;
                   width:20ch;
                   line-height: 110% !important;
               }
           }
        
       }

      .left {
        display: flex;
        flex-direction: column;
        text-align: center;
        // border: 1px solid red;
        gap: 12px;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 4px solid var(--accent);
          border-radius: 100px;
          padding: 5px 10px;
          color: var(--white);
          background: var(--accent);
          gap: 10px;
          font-size: 26px;

          &:hover {
            color: var(--accent);
            background: var(--white);
            border: 4px solid var(--accent);
          }
        }
        @media only screen and (max-width:600px){
            .icon{
                font-size: 20px;
            }
        }
        .linked {
          text-decoration: underline;
          text-align: center;
          line-height: 100%;
        }
      }
    }
  }
}


