.page-not-found-parent{
  height: calc(100svh - 60px);
    width: 100%;

    .page-not-found-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap:40px;
        justify-content: center;
        align-items: center;
        .headding-div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            .head1{
                font-size: 50px;
                font-weight: 900;
                letter-spacing: 5px;

            }
            .head2{
                font-size: 40px;
                font-weight: 700;
                margin: 0;
            }
        }


        .fourp-img-box{
            width: 100%;
            height: 75px;

            .fourp-img{
                height: 100%;
                width: 100%;
                background: url('../assets/four-0-4/pagenotfound.webp');
            }
        }

        }

    }

