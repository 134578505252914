.about-parent {
    margin: 50px 0px;

    .about-cont {
        display: flex;
        width: 100%;
        height: 600px;
        padding: 20px;
        border-radius: var(--rad);


        .about-left {
            width: 50%;
            background: url("../photos/about/latur1.webp");
            aspect-ratio: 1;
            border-radius: 10px 0px 0px 10px;


            &.about-right {
                background: url("../photos/about/latur2.webp");
            }
        }

        .about-right {
            width: 50%;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: calc(var(--gap)/2);
            padding: 10px;
            background-color: var(--grey);
            border-radius: 0px 10px 10px 0px;
            justify-content: center;
            

            .h4-heading{
            font-weight: 700;
            }

            span{
                cursor: pointer;
            }


     
        
            &.scroll-about {
                overflow: scroll;
                overflow-x: hidden;
                justify-content: start;
            }
        
            &.scroll-about::-webkit-scrollbar {
                width: 3px; 
            }
        
            &.scroll-about::-webkit-scrollbar-thumb {
                background-color: var(--accent);
                border-radius: 6px;
            }
        
            &.scroll-about::-webkit-scrollbar-track {
                background-color: var(--scrollbar-track-color); 
            }
        }
    }

}

#about-left{
    border-radius: 10px 0px 0px 10px;
}

//   {/* ...........//Personality Section//............. */}

.personality {
    background: var(--grey);
    padding: 50px 0px;

    .personality-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        // background: #ffffff;
        gap: 10px;

        .mySwiper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
            .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                background: var(--accent);
            }

            .swiper-button-prev:after,
            .swiper-button-next:after {
                display: none;
            }

            @media only screen and (max-width: 1000px) {

                .swiper-button-prev:after,
                .swiper-button-next:after {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--accent);
                    padding: 10px;
                    color: var(--white);
                    font-size: 16px;
                    border-radius: 100%;
                }
            }
        }

        .personality_twobox_cont {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // background: #ffffff;
            border-radius: 10px;
            gap: 20px;
            margin-bottom: 50px;

            .personality_img {
                width: 40%;
                height: 500px;
                border-radius: 10px;
            }

            .personality_img-1 {
                background: url("../photos/personality/s1.jpg");
            }

            .personality_img-2 {
                background: url("../photos/personality/bjp.jpg");
            }

            .personality_img-3 {
                background: url("../photos/personality/S3.jpg");
            }

            .personality_img-4 {
                background: url("../photos/personality/S4.jpg");
            }

            .personality_img-5 {
                background: url("../photos/personality/S5.jpg");
            }

            .personality_img-6 {
                background: url("../photos/personality/S_6.jpg");
            }

            .personality_img-7 {
                background: url("../photos/personality/S7.jpg");
            }

            .personality_img-8 {
                background: url("../photos/personality/S8.jpg");
            }

            .personality_img-9 {
                background: url("../photos/personality/S9.jpg");
            }

            .personality_content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 500px;
                border-radius: 10px;
                background: white;
                padding: 30px;
            }
        }

        @media only screen and (max-width: 900px) {
            .personality_twobox_cont {
                flex-direction: column !important;
                gap: 10px;
                padding-top: 20px;
                background: var(--white);

                .personality_img {
                    height: 300px;
                    width: 90%;
                }

                .personality_content {
                    padding: 10px 0px 10px 0px;
                    width: 90%;
                    height: 400px;
                    align-items: flex-start;
                }
            }
        }

        @media only screen and (max-width: 800px) {
            .personality_twobox_cont {
                flex-direction: column !important;
                gap: 10px;
                padding-top: 20px;
                background: var(--white);

                .personality_img {
                    height: 300px;
                    width: 90%;
                }

                .personality_content {
                    padding: 10px 0px 10px 0px;
                    width: 90%;
                    height: 550px;
                    align-items: flex-start;
                }
            }
        }

        @media only screen and (max-width: 500px) {
            .personality_twobox_cont {
                .personality_img {
                    height: 300px;
                    width: 90%;
                }

                .personality_content {
                    width: 90%;
                    height: 600px;
                    overflow-y: scroll;
                }
            }

            
        }
    }
}

@media only screen and (min-width: 500px) and (max-width: 850px) {
    .lists_sam {
        .list_sam_container {
            .sam_twobox_cont {
                flex-direction: column-reverse;
                height: auto;

                .sam_img {
                    width: 80%;
                }

                .sam_content {
                    height: auto;
                    width: 80%;
                    padding: 15px;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .lists_sam {
        .list_sam_container {
            .sam_twobox_cont {
                flex-direction: column-reverse;
                height: auto;

                .sam_img {
                    width: 100%;
                }

                .sam_content {
                    width: 100%;
                    padding: 10px;
                    height: auto;
                }
            }
        }
    }


    .about-cont{
        width: 100% !important;
        flex-direction: column !important;
        height: auto !important;


        .about-left{
            width: 100% !important;

            border-radius: 10px 10px 0px 0px !important;
        }


        .about-right{
            width: 100% !important;
            border-radius: 0px 0px 10px 10px !important;
        }
     }
      

     #about-right{
        border-radius: 10px 10px 0px 0px !important;
     }
     #about-left{
        border-radius: 0px 0px 10px 10px !important;
     }
}