.hero-parent {
  width: 100%;
  height: 100svh;

  .mySwiper {
    height: 100%;
    width: 100%;

    .swiper-pagination-bullet {
      background: var(--white) !important;
      width: 20px;
      height: 20px;
    }

    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }

    .swiper-pagination-bullet {
      color: var(--accent);
      display: none;
    }

  }

  .overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.23);
    z-index: -1;
    width: 100%;
    height: 100%;
    animation: trans infinite 6s ease-in-out;


    @keyframes trans {
      to {
        transform: scale(1.1);
      }
    }
  }

  .slide-img {
    height: 100%;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: -1;
    position: relative;
    animation: zoom-bg forwards 6s ease-in-out;

    @keyframes zoom-bg {
      to {
        transform: scale(1.1);
      }
    }

    @keyframes zoom-main {
      0% {
        opacity: 0;
      }

      10% {
        opacity: 1;
        transform: scale(1.2);
      }

      30% {
        opacity: 1;
        transform: scale(1);
      }

      100% {
        transform: scale(1);
      }
    }

    @keyframes zoom-h1 {
      0% {
        opacity: 0;
        transform: translateX(-50%) translateY(100%);
      }

      40% {
        opacity: 1;
        transform: translateX(-50%) translateY(0%);
      }

      100% {
        transform: scale(1.04) translateX(-50%) translateY(0%);
      }
    }

    .slide-main {
      height: 600px;
      width: auto;
      aspect-ratio: 1;
      background: url("../assets/home/123.png");
      position: absolute;
      bottom: 0px;
      z-index: -2;
      animation: zoom-main forwards 6s ease-in-out;
    }

    h1 {
      font-size: 50px;
      line-height: 120%;
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%) translateY(0%);
      max-width: 45ch;
      color: var(--white);
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      animation: zoom-h1 forwards 6s ease-in-out;
    }
  }

  .slide1 {
    background: url("../assets/home/bg2.png");

  }

  .slide2 {
    background: url("../photos/heroSlider/s2_bgimg.png");

    .slide-main {
      background: url("../photos/heroSlider/spn.png"); //thiikee
      height: 700px;
      z-index: -1;
    }

    // .spn-frame {
    //   background: url("../photos/heroSlider/photos_spn.png");
    //   height: 100%;
    //   width: 100%;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   display: none;
    // }
  }

  .slide3 {
    background: url("../assets/home/bg_s3.jpg");

    .slide-main {
      background: url("../assets/home/hero_s3.png");
      height: 700px;
      z-index: -1;
    }
  }
}

@media only screen and (max-width: 750px) {
  .hero-parent {
    height: 100svh;

    // margin-top: 60px !important;
    .slide-img .slide-main {
      height: 60% !important;
      aspect-ratio: 1;
      height: auto;
    }

    h1 {
      font-size: 30px !important;
      max-width: 80vw !important;
      margin-bottom: 50px !important;
      line-height: 120% ;
      width: 100% !important;
      bottom: 10% !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  .hero-parent {
    height: auto !important;
    overflow-y: hidden !important;

    .slide-img .slide-main {
      height: 70% !important;
      aspect-ratio: 1;
      height: auto;
    }

    h1 {
      margin-bottom: 40px !important;     
    }

    .slide1-text-global {
      .slider-h2 {
        text-align: center;
        text-wrap: wrap !important;
        font-size: 20px;
      }
    }
  }
}


@media only screen and (max-width: 470px) {
  .hero-parent {
    height: 700px !important;

    h1 {
      margin-bottom: 40px !important;     
    }
  }
}

@media only screen and (max-width: 400px) {
  .hero-parent {
    height: 700px !important;

    h1 {
      margin-bottom: 60px !important;    
    }
  }
}

@media only screen and (max-height: 750px){
   .slide-main {
    height: 100% !important;
    aspect-ratio: 1;
    height: auto;
  }
}