.laturmap {
  .laturmap-cont {
    padding: 0px 10px;
    //   background: green;
    position: relative;
    img {
      // height: 100dvh;
      width: 100%;
    }
    @media only screen and (max-width: 500px) {
      img {
        height: 500px !important;
      }
    }

    .lbox {
      background-color: rgba(255, 255, 255, 0.308);
      position: absolute;

      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 250px;
      border-radius: 100%;
      border: 1px solid black;
      cursor: pointer;
      right: 0;
      svg {
        color: red;
        font-size: 40px;
        animation: scale-up 2s infinite ease-in-out;
      }

      &.box1 {
        right: 52%;
        top: 8%;
      }

      &.box2 {
        right: 20%;
        top: 25%;
      }
      &.box3 {
        right: 60%;
        top: 45%;
      }
    }
    @media only screen and (max-width: 1000px) {
      .lbox {
        width: 200px;
        height: 200px;
      }
    }
    @media only screen and (max-width: 750px) {
      .lbox {
        width: 150px;
        height: 150px;
        &.box1 {
          top: 15%;
        }
        &.box2 {
          top: 30%;
        }
        &.box3 {
          top: 50%;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      .lbox {
        width: 80px;
        height: 80px;
        &.box1 {
          top: 20%;
        }
        &.box2 {
          top: 40%;
        }
        &.box3 {
          top: 60%;
        }
      }
    }
  }
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
