.taas-parent {


  background: url("../assets/72_taas_andolan.jpg");



  .taas-overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #000000b4, #00000000);
    position: absolute;
  }

  .taas-headding1 {
    position: absolute;
    z-index: 2;
    color: white;
    padding-left: 30px;
    bottom: 20px;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
}

h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 700;
}



@media only screen and (max-width:500px) {
  .taas-parent {

    .taas-overlay {}

    .taas-headding1 {}
  }

  h3 {

    font-size: 25px !important;
  }
}