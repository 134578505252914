.twoColSec {
  height: auto;
  padding: 30px 10px;


  .twoColSec-cont {
    display: flex;
    align-items: center;

    .sec-left {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;

      .img-box {
        width: 90%;
        // aspect-ratio: 1;
        height: 700px;
        border-radius: var(--rad);
        // box-shadow: var(--shadow);
        background: rgb(209, 209, 209);
      }
    }

    .sec-right {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: calc(var(--gap)/2);

      h2 {
        margin-bottom: var(--gap);     
      }
      p {
        margin-bottom: var(--gap);
      }

      .sub-heading {
        margin-top: -23px;
      }
    }
  }
}


@media only screen and (max-width: 1000px) {
  .twoColSec-cont {
    flex-direction: column !important;

    .sec-right {
      width: 95% !important;
      align-items: center !important;
      border-radius: 20px !important;

    }

    .sec-left {
      width: 100% !important;

      .img-box {
        aspect-ratio: 1 !important;
        height: 500px !important;
        // width: 800px !important;
        background-position: center !important;
        // background-size: contain !important;
        border-radius: 10px !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .twoColSec-cont {
    flex-direction: column !important;
padding: 10px;
    .sec-left{
      width: 100%;
      
    }
    .sec-right {
      width: 100% !important;
      align-items: center !important;

      
      h2 {
    
        font-size: 20px !important;
      }
      .sub-heading {
        font-size: 20px !important;
      
      }
    }

    .sec-left {
      width: 100% !important;
      padding-left:10px !important ;
      padding-right:10px !important ;

      .img-box {
        // aspect-ratio: 1 !important;
        height: 100% !important;
        width: 100% !important;
      }
    }

    .sec-right {
      gap: 0px !important;
  }

  }
}