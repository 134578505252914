.imageswiper-parent {
    // margin-top: 70px;
    height: auto;
    padding: 50px 0px;
    padding-bottom: 40px;
//    position: relative;
    .imageswiper-conatiner {
        height: 100%;
        width: 100%;
        // position: absolute;
        // top:100px;
        // // left:0;
        .titles{
            padding:0px 0px 20px 0px;
            display:flex;
            align-items:center;
            justify-content:center;
            font-size: 30px;
            font-weight: 700;
        }
        .mySwiper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
                background: var(--accent);
            }
        }
        .img-card-parent {
            border-radius: 10px;
            width: 380px;
            height: 500px;
            border-radius: 10px;
            margin-bottom: 70px;
           margin-left: auto;
           margin-right: auto;
            .card-img {
                height: 100%;
                width: 100%;
                background: url('../assets/s2.jpg');
                border-radius: 10px;
            }
        }
    }
}
// @media only screen and (max-width: 1200px){
// .imageswiper-parent{
// padding-top: 220px !important;
// }
// }
// @media only screen and (max-width: 1100px){
// .imageswiper-parent{
// padding-top: 250px !important;
// }
// }
// @media only screen and (max-width: 950px){
// .imageswiper-parent{
// padding-top: 320px !important;
// }
// }
// @media only screen and (max-width: 750px){
// .imageswiper-parent{
// padding-top: 20px !important;
// }
// @media only screen and (max-width:500px){
//     .imageswiper-parent {
     
//         .imageswiper-conatiner {
         
//             .titles{
//                 font-size: 25px !important;
                
//             }
//         }
//     }
// }
// }