.header {
    height: 60px;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0px;
    z-index: 15;
    // background: rgba(0, 0, 0, 0.148);
    backdrop-filter: blur(5px);
    background: var(--white);
    position: sticky;



    &.scrolled {
        // background: var(--white);
        box-shadow: var(--shadow);

        // .header-cont .links a:not(.btn) {
        //     color: var(--text) !important;
        // }

    }

    .header-cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100% !important;
        max-width: none !important;
        padding: 0px 50px;
        height: 100%;

        .logo {
            height: 100%;
            display: flex;
            align-items: center;

            img {
                height: 80%;
            }

            h2 {
                font-weight: 700;
                margin-left: 10px;
                font-family: "Alkatra", system-ui !important;
                font-optical-sizing: auto;
                // font-weight: 600;
                font-style: normal;
                font-size: 2vw;
            }
        }

        .links {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: var(--gap);

            a:not(.btn) {
                text-decoration: none;
                font-weight: 500;
                color: var(--text);

                &:hover,
                &.active {
                    color: var(--accent);
                }
            }

            .ant-dropdown-link {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: var(--text);
                font-weight: 500;


                .header-arrow {
                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    // justify-content: center;

                    svg {
                        font-size: 30px;
                    }
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: calc(var(--gap)/2);
                position: relative;

                p {
                    font-weight: inherit;
                    font-size: inherit;
                }

                .img-new-btn {
                    height: 30px;
                    aspect-ratio: 1;
                    position: absolute;
                    top: -80%;
                    right: -15%;
                    filter: hue-rotate(-93deg);
                    animation: coloranimate 1s infinite linear;
                    rotate: 33deg;

                    @keyframes coloranimate {
                        0% {
                            filter: hue-rotate(0deg);
                        }

                        10% {
                            filter: hue-rotate(11deg);
                        }

                        30% {

                            filter: hue-rotate(-18deg);
                        }

                        60% {
                            filter: hue-rotate(-37deg);
                        }

                        70% {
                            filter: hue-rotate(-75deg);
                        }

                        0% {
                            filter: hue-rotate(0deg);
                        }


                    }
                }
            }
        }

        .menu {
            // display: none;
            height: 50px;
            aspect-ratio: 1;
            display: none;
            align-items: center;
            justify-content: center;
            font-size: 40px;
        }
    }


}

.dropdown {
    margin-top: 15px !important;
}

// mobile nav starts here

.mob-nav {
    background: var(--white);
    z-index: 10;
    min-height: 100vh;
    width: 0vw;
    position: fixed;
    top: 0px;
    right: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: var(--trans);
    padding-top: 100px;
    display: none !important;


    &.navopen {
        width: 100vw;
        right: 0px;

        .close {
            font-size: 40px;
            display: flex !important;
        }

        .moblinks {
            a {
                font-size: 20px !important;



                .img-new-btn {
                    display: flex;
                }
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 50px;
        aspect-ratio: 1;
        display: none;
        align-items: center;
        justify-content: center;
        font-size: 0px;
        transition: var(--trans);
    }

    .moblinks {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc(var(--gap) * 1);
        height: 100%;
        overflow-y: hidden;
        padding: 0px 15px;
        overflow-x: hidden !important;

        .ant-dropdown-link-about {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;

            .header-arrow-about {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 13px;
            }
        }

        .about-dropdown {

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 35px;
                font-weight: 300;
                color: black;
            }
        }


        a:not(.btn) {
            text-decoration: none;
            font-weight: 500;
            font-size: 0px;
            color: var(--text);
        }

        .arrow-mob {
            display: flex;
            align-items: center;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: calc(var(--gap)/2);
            position: relative;
            width: 100%;

            p {
                font-weight: inherit;
                font-size: inherit;
            }

            .img-new-btn {
                height: 30px;
                aspect-ratio: 1;
                position: absolute;
                top: -15%;
                right: -15%;
                filter: hue-rotate(-93deg);
                animation: coloranimate 1s infinite linear;
                overflow: hidden;
                display: none;

                @keyframes coloranimate {
                    0% {
                        filter: hue-rotate(0deg);
                    }

                    10% {
                        filter: hue-rotate(11deg);
                    }

                    30% {

                        filter: hue-rotate(-18deg);
                    }

                    60% {
                        filter: hue-rotate(-37deg);
                    }

                    70% {
                        filter: hue-rotate(-75deg);
                    }

                    0% {
                        filter: hue-rotate(0deg);
                    }


                }
            }


        }

    }

    .dropdown-mob {
        height: 350px;
        /* Adjust the max-height as needed */
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background: #efefef;
        color: white;
        padding: 20px 20px;
        padding-top: 120px;
        border-radius: var(--rad);
        scrollbar-width: none;
    }
}



@media only screen and (max-width: 1000px) {
    .menu {
        display: flex !important;
    }

    .links {
        display: none !important;
    }

    .mob-nav {
        display: flex !important;
    }

    .moblinks {
        overflow-y: scroll !important;
        width: 100%;

        .img-new-btn {
            right: 43% !important;
            top: -80% !important;
            rotate: 33deg;
        }
    }
}

@media only screen and (max-width: 500px) {

    .header-cont {
        padding: 0px 10px !important;
    }

    .logo {
        height: 100% !important;
        display: flex !important;
        align-items: center !important;

        img {
            height: 80% !important;
        }

        h2 {
            font-weight: 700 !important;
            font-size: 5vw !important;
            text-align: left !important;
            line-height: 20px;
        }
    }

    .moblinks {
        overflow-y: scroll !important;
        width: 100%;

        .img-new-btn {
            right: 40% !important;
            rotate: 33deg;
        }
    }
}

.toprow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center
    }
}

.ant-dropdown-link-about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;

    .header-arrow-about {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 500;
    }
}


.dropdown-mob1 {
    height: 90px !important;
    overflow-y: hidden !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #efefef;
    color: white;
    padding: 20px 20px;
    border-radius: var(--rad);
    scrollbar-width: none;
}