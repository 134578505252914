.atal-parent {


  background: url("../assets/Atal_Maharogya_Shibir.jpg");



  .atal-overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #000000b4, #00000000);
    position: absolute;
  }

  .atal-headding1 {
    position: absolute;
    z-index: 2;
    color: white;
    padding-left: 30px;
    bottom: 20px;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
}

@media only screen and (max-width:500px) {
  .atal-parent {
    .atal-overlay {}

    .atal-headding1 {
      font-size: 35px !important;
      padding-left: 0px !important;
    }
  }
}