.akka-parent {
  height: 600px;
  width: 100%;
  background: url("../assets/Akka_Foundation.jpg");
  position: relative;
  display: flex;
  justify-content: center;

  .akka-overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #000000b4, #00000000);
    position: absolute;
  }

  .akka-headding1 {
    position: absolute;
    z-index: 2;
    color: white;
    padding-left: 30px;
    bottom: 20px;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
}


.counter-parent-main {

  .counter-hr{
    border: 1px solid rgba(0, 0, 0, 0.059);
    // margin-top: -10px !important;
    // margin-bottom: 20px ;
  }

  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin: auto;
  gap: 20px;
  .counter-parent11{
  // border: 1px solid rgba(0, 0, 0, 0.059);
  display: flex;
  flex-direction: column;
  gap:20px;
  .akka-head1{
   margin: 0px !important;
    // border: 1px solid red;
    }
  }


  

.counter-parent12{
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  gap:20px;
  .akka-head2{
margin-bottom: -40px;
  //  margin: 0px !important;
   padding: 0px !important;
  }
  padding-bottom: 50px;
}

  gap: var(--gap);
}

.akka-found-parent {
  height: 500px;
  width: 100%;

  .overlay-swiper {
    height: 500px;
    width: 100%;
    background: linear-gradient(90deg,
        rgba(0, 0, 0, 0.89) 50%,
        rgba(0, 0, 0, 0.184));
    z-index: 1;
    position: absolute;
  }

  .mySwiper {
    height: 100%;
    width: 100%;
  }

  .slide-img {
    width: 100%;
    height: 100%;
  }

  .slide-img1 {
    background: url("../photos/akka_swiper/s1.webp");
  }

  .slide-img2 {
    background: url("../photos/akka_swiper/education.webp");
  }

  .slide-img3 {
    background: url("../photos/akka_swiper/environment.webp");
  }

  .slide-img4 {
    background: url("../photos/akka_swiper/health.webp");
  }

  .slide-img5 {
    background: url("../photos/akka_swiper/art_culture.webp");
  }

  .slide-img6 {
    background: url("../photos/akka_swiper/anandi.webp");
  }

  .swiper-button-next,
  .swiper-button-prev,
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: var(--accent);
    border-radius: 100%;
    font-size: 20px;
    font-weight: 600;
    background-color: rgba(192, 191, 191);
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  .swiper-pagination-bullet {
    background-color: var(--white);
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet-active {
    width: 15px;
    height: 15px;
  }

  .swiper-section {
    display: flex;
    margin: auto;
    padding: 24px;
    width: 100%;
    height: 100%;

    .left-swiper {
      z-index: 20;
      position: relative;
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: calc(var(--gap));
      left: 10%;

      .swiper-heading {
        font-size: 30px;
        font-weight: 700;
        color: var(--white);
        justify-content: left;
        margin-bottom: 0px;
      }

      .swiper-para {
        color: var(--white);
      }

      .swiper-btn {
        &:hover {
          background: white;
          color: var(--accent) !important;
          border: none;
        }
      }
    }

    .right-swiper {
      width: 60%;
    }
  }
}

@media only screen and (max-width: 1200px) {

  .counter-parent-main {
    flex-direction: column !important;

  }

  .overlay-swiper {
    background: rgba(0, 0, 0, 0.781) !important;
  }

  .swiper-section {
    align-items: center !important;
    justify-content: center !important;

    .left-swiper {
      width: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      left: 0 !important;

      .swiper-heading {
        display: flex !important;
        justify-content: left !important;
      }

      // .swiper-btn {
      // }
    }

    .right-swiper {
      display: none !important;
    }
  }
}

// .counter-akka{
//   padding-top: 5px !important;
// }
@media only screen and  (max-width:700px){
  .counter-parent-main .counter-parent12 .akka-head2{
    margin-bottom: -10px !important;
    padding-top: 10px;
  }
}