.cardswiperv-parent {
    padding: 50px 10px;
    height: 100svh;
    
    

    .cardswiperv-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .mySwiperv {
            width: 100%;
            height: auto;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0px;
        }

        .swiper-cardv {
            margin: auto;
            width: 330px !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: var(--shadow);
            border-radius: calc(var(--gap) / 2);
            overflow: hidden;
            background-color: var(--white);

            .cardv-img {
                width: 100%;
                aspect-ratio: 1;
                // background: url('../assets/s1.jpg');
            }

            .cardv-content {
                display: flex;
                flex-direction: column;
                gap: calc(var(--gap)/2);
                padding: 10px 10px 20px 10px;
                align-items: center;

                .cardv-heading {
                    text-align: center;
                    font-size: 20px;
                }
            }
        }







    }
}