.workdetail-parent {
  height: 100vh;
  width: 100%;
  padding: 100px 0px;
  background-color: var(--grey);

  .workdetail-cont {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow);
    border-radius: calc(var(--gap) / 2);
    overflow: hidden;
    padding: 0px;
    padding: var(--gap);

    .left {
      width: 30%;
      height: 100%;
      border-right: 1px solid var(--accent);
      padding: 0px var(--gap);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: var(--gap);
      background-color: var(--accent);
      border-radius: calc(var(--gap) / 2);
      .secondinput {
        display: none;
        height: 10%;
        position: relative;
        width: 100%;

        input {
          width: 100%;
          padding: calc(var(--gap) / 2);
          border: none;
          outline: none;
          border-radius: calc(var(--gap) / 3);
          background-color: rgba(128, 128, 128, 0.329);
          color: var(--white);
          padding-left: 15px;

          &::selection {
            background-color: var(--text);
            /* Background color */
            color: var(--white);
            /* Text color */
          }

          /* For Firefox */
          &::-moz-selection {
            background-color: var(--text);
            /* Background color */
            color: var(--white);
            /* Text color */
          }

          &::placeholder {
            color: rgb(211, 211, 211);
            text-transform: capitalize;
          }

          &:focus {
            border: none;
            outline: 1px solid var(--white);
            background-color: transparent;
          }
        }

        span {
          position: absolute;
          right: 5%;
          top: 15%;

          svg {
            color: var(--white);
          }
        }
      }

      .input {
        width: 100%;
        height: 10%;
        padding: var(--gap) 0px;
        border-bottom: 1px solid var(--text);
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          width: 100%;
          padding: calc(var(--gap) / 2);
          border: none;
          outline: none;
          border-radius: calc(var(--gap) / 3);
          background-color: rgba(128, 128, 128, 0.329);
          color: var(--white);
          padding-left: 15px;
          outline: 1px solid var(--text);

          &::selection {
            background-color: var(--text);
            /* Background color */
            color: var(--white);
            /* Text color */
          }

          /* For Firefox */
          &::-moz-selection {
            background-color: var(--text);
            /* Background color */
            color: var(--white);
            /* Text color */
          }

          &::placeholder {
            color: rgb(211, 211, 211);
            text-transform: capitalize;
          }

          &:focus {
            border: none;
            outline: 1px solid var(--white);
            background-color: transparent;
          }
        }

        span {
          position: absolute;
          right: 5%;
          top: 15%;

          svg {
            color: var(--white);
          }
        }
      }

      .village-list {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 80%;
        overflow-y: scroll;
        position: relative;
        direction: rtl;

        // border-left: 1px solid var(--white);

        &.village-list::-webkit-scrollbar {
          width: 8px;
        }

        &.village-list::-webkit-scrollbar-thumb {
          background-color: var(--white);
          border-radius: 6px;
        }

        &.village-list::-webkit-scrollbar-track {
          border-radius: 6px;
          background-color: rgba(128, 128, 128, 0.548);
        }

        gap: calc(var(--gap) / 3);

        .village {
          width: 90%;
          // padding: calc(var(--gap) / 4);
          // border: 1px solid white;
          position: relative;
          padding-left: 0px;
          display: flex;
          flex-direction: column;

          p {
            width: 100%;
            color: var(--white);
            cursor: pointer;
            text-align: left;
            padding-left: 50px;
            position: relative;
            line-height: 130% !important;
          }
          &::before {
            height: 125%;
            width: 30px;
            position: absolute;
            border-top: 1px solid rgb(143, 143, 143);
            border-left: 1px solid rgb(143, 143, 143);
            content: "";
            left: 0;
            top: 50%;
          }

          &:last-child {
            &::before {
              border-left: none;
            }
          }
        }
      }
    }

    .right {
      width: 70%;
      height: 100%;

      padding: 0px var(--gap);

      .top-heading {
        width: 100%;
        // height: 10%;
        padding: var(--gap);
        border-bottom: 1px solid var(--text);
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
// border: 1px solid red;
        .title {
          color: var(--black);
          font-weight: 500;
          margin-bottom: 0px !important;
          text-align: center;
          text-transform: capitalize;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: calc(var(--gap) / 3);
          display: inline;
          span {
            color: var(--accent);
            font-weight: 500;
          }
        }
      }

      .details-village {
        height: 80%;
        width: 100%;
        padding: calc(var(--gap) / 2) 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: var(--gap);
        flex-direction: column;
        .swiper-pagination-bullet {
          background: var(--accent);
        }
        .swiper-pagination-bullet-active {
          width: 20px;
          border-radius: 10px !important;
          transition: var(--trans);
        }
        .village-image-swiper {
          width: 100%;
        }
        .work-image {
          height: 400px;
          width: 100%;
          border-radius: calc(var(--gap) / 2);
        }

        .details-work-details {
          height: fit-content;
          width: 100%;
          display: flex;
          align-items: center;
          gap: calc(var(--gap) / 2);
          flex-wrap: wrap;

          span {
            font-weight: 500 !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .workdetail-parent {
    height: fit-content;
    .workdetail-cont {
      padding: 10px !important;
    }
    .right {
      .details-village {
        .workdetails-left {
          p {
            font-size: calc(var(--gap) - 5px);
            line-height: 120%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .workdetail-cont {
    flex-direction: column !important;

    .left {
      width: 100% !important;
      height: 300px !important;
      padding: 10px !important;

      .secondinput {
        display: flex !important;
        height: 100% !important;
        span {
          top: 30% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .input {
        // display: none !important;
      }

      .village-list {
        // display: none !important;
        // background: green;
      }
    }

    .right {
      width: 100% !important;
      height: 90% !important;
    }
  }
}

.popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.623);
  position: fixed;
  top: 0;
  left: 0;
  padding: 80px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .left {
    width: 30%;
    height: 100%;
    border-right: 1px solid var(--accent);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: var(--gap);
    padding: 10px;
    background-color: var(--accent);

    .input {
      height: 10%;
      position: relative;
      width: 100%;
      input {
        width: 100%;
        padding: calc(var(--gap) / 2);
        border: none;
        outline: none;
        border-radius: calc(var(--gap) / 3);
        background-color: rgba(128, 128, 128, 0.329);
        color: var(--white);
        padding-left: 15px;

        &::selection {
          background-color: var(--text);
          /* Background color */
          color: var(--white);
          /* Text color */
        }

        /* For Firefox */
        &::-moz-selection {
          background-color: var(--text);
          /* Background color */
          color: var(--white);
          /* Text color */
        }

        &::placeholder {
          color: rgb(211, 211, 211);
          text-transform: capitalize;
        }

        &:focus {
          border: none;
          outline: 1px solid var(--white);
          background-color: transparent;
        }
      }

      span {
        position: absolute;
        right: 5%;
        top: 15%;

        svg {
          color: var(--white);
        }
      }
    }

    .village-list {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: var(--gap);
    }

    .image-right {
      width: 40%;
      height: 90%;
      background-color: red;
      border-radius: calc(var(--gap) / 2);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .workdetail-parent {
    .workdetail-cont {
      padding: 10px 10px !important;
    }

    .right {
      padding: 0px !important;
      .details-village {
        .workdetails-left {
          p {
            font-size: calc(var(--gap) - 5px);
            line-height: 120%;
          }
        }
      }
    }
  }
}

.popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.623);
  position: fixed;
  top: 0;
  left: 0;
  padding: 80px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .left {
    width: 30%;
    height: 100%;
    border-right: 1px solid var(--accent);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: var(--gap);
    padding: 10px;
    background-color: var(--accent);

    .input {
      height: 10%;
      position: relative;
      width: 100%;

      input {
        width: 100%;
        padding: calc(var(--gap) / 2);
        border: none;
        outline: none;
        border-radius: calc(var(--gap) / 3);
        background-color: rgba(128, 128, 128, 0.329);
        color: var(--white);
        padding-left: 15px;

        &::selection {
          background-color: var(--text);
          /* Background color */
          color: var(--white);
          /* Text color */
        }

        /* For Firefox */
        &::-moz-selection {
          background-color: var(--text);
          /* Background color */
          color: var(--white);
          /* Text color */
        }

        &::placeholder {
          color: rgb(211, 211, 211);
          text-transform: capitalize;
        }

        &:focus {
          border: none;
          outline: 1px solid var(--white);
          background-color: transparent;
        }
      }

      span {
        position: absolute;
        right: 5%;
        top: 15%;

        svg {
          color: var(--white);
        }
      }
    }

    .village-list {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 80%;
      overflow-y: scroll;
      position: relative;

      // border-left: 1px solid var(--white);

      &.village-list::-webkit-scrollbar {
        width: 3px;
      }

      &.village-list::-webkit-scrollbar-thumb {
        background-color: var(--white);
        border-radius: 6px;
      }

      &.village-list::-webkit-scrollbar-track {
        background-color: var(--scrollbar-track-color);
      }

      gap: calc(var(--gap) / 3);

      .village {
        width: 80%;
        position: relative;

        p {
          width: 100%;
          color: var(--white);
          cursor: pointer;
          text-align: left;
          padding-left: 50px;
          position: relative;

          &::before {
            height: 150%;
            width: 30px;
            position: absolute;
            border: 1px solid var(--white);
            border-right: none;
            border-bottom: none;
            content: "";
            left: 0;
            top: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .workdetail-parent {
    height: auto !important;

    .workdetail-cont {
      .right {
        .top-heading {
          .title {
            font-size: calc(var(--gap) - 5px) !important;
          }
        }

        .details-village {
          // flex-direction: column-reverse !important;

          .workdetails-left {
            width: 100%;
          }

          .image-right {
            width: 100%;
            aspect-ratio: 0.8;
          }
        }
      }
    }
  }

  .popup {
    .left {
      width: 100% !important;
    }
  }
}

@media only screen and (max-height: 1000px) {
  .workdetail-parent {
    padding: 30px 0px !important;
  }
}
@media only screen and (max-width: 500px) {
  .details-work-details {
    flex-direction: column !important;
  }
}

.work-det-desc {
  font-size: 12px;
  line-height: 100% !important;
  opacity: 0.8;
  display: inline;
}


.placeholder-no-photo 
{
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}