.accordian {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin: 0px;

    .topbar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--white);
        padding: 10px 20px;
        border-radius:calc( var(--rad) /2);
        width: 100%;

        h3{
          text-align: left !important;
        }


    }



  .bottombar {
    background: rgba(0, 0, 0, 0.068);
    border-radius:calc( var(--rad) /2);
    padding: 10px 20px;
    margin-top: 10px;
    width: 100%;

    
    
  }
}
