.privacy>* {
    padding: 5px 0px;

  
}

.privacy 
{
    padding: 10px 15px;
    max-width: var(--max-width);
    margin: 100px auto 10px auto;
    h1{
        font-size: 28px!important;
        font-weight: 800;
    }
    h2{
        font-size: 24px!important;
    }
    p{
        font-size: 16px!important;
    }

    .cookies_ul{
        line-height: 25px;
        padding: 0px 23px;
    }
}