.blog-parent {
    margin-top: 70px;
    padding: 50px 10px;
    height: auto;

    .blog-container {
        display: flex;

        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;

        .blog-headding {
            width: 100%;
          text-align: center;
        }

        .blog-card {
            width: 300px;
            height: auto;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18);
            overflow: hidden;


            .blog-card-img {
                width: 300px;
                height: 200px;
                position: relative;

                .blog-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .blogdate{
                    position: absolute;
                    top:10px;
                    right: -5px;
                    background:var(--white);
                    border-radius:5px;
                    padding:5px;
                }
            }

            .blog-card-bottom {

                display: flex;
                gap: 10px;
                flex-direction: column;
                padding: 10px;
                align-items: center;
                justify-content: center;

                .blog-title {
                    text-align: center;
                    color: black;
                    font-size: 22px;
                    font-weight: 300;
            
                }

                .blog-text {}
            }
        }
    }



}



@media only screen and (max-width: 550px) {
    .blog-parent {
        margin-top: 0px !important;
    }
}