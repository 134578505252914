.success {
    background: #efefef;
    padding: 50px 0px;

    .success-container {

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        // background: #ffffff;
        gap: 10px;

        .mySwiper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
            .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                background: var(--accent);

            }



            @media only screen and (max-width:1000px) {

                .swiper-button-prev:after,
                .swiper-button-next:after {
                    display: block !important;
                    background: var(--accent);
                    padding: 10px;
                    color: var(--white);
                    font-size: 16px;
                    border-radius: 100%;
                }
            }
        }

        .success_twobox_cont {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // background: #ffffff;
            border-radius: 10px;
            gap: 20px;
            margin-bottom: 50px;

            .success_img {
                width: 40%;
                height: 400px;

                // margin: 10px;
                border-radius: 10px;
                background: url("../assets/s1.jpg");

            }

            .success_content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60%;
                height: 400px;
                border-radius: 10px;
                background: white;
                padding: 30px;


            }
        }

        @media only screen and (max-width:1000px) {
            .success_twobox_cont {


                .success_img {
                    height: 500px;

                }

                .success_content {
                    height: 500px;


                }
            }
        }

        @media only screen and (max-width:900px) {
            .success_twobox_cont {
                flex-direction: column-reverse !important;
                gap: 10px;

                padding-top: 20px;
                background: var(--white);

                .success_img {
                    width: 90%;
                    height: 300px;

                }

                .success_content {
                    padding: 10px 0px 10px 0px;
                    width: 90%;
                    height: 400px;
                    align-items: flex-start;

                }
            }
        }

        @media only screen and (max-width:650px) {
            .success_twobox_cont {
                .success_img {
                    width: 90%;
                    height: 300px;
                }

                .success_content {
                    width: 90%;
                    height: 500px;
                }
            }
        }

        @media only screen and (max-width:500px) {
            .success_twobox_cont {
                .success_img {
                    width: 90%;
                    height: 300px;
                }

                .success_content {
                    width: 90%;
                    height: 600px;
                }
            }

        }

        @media only screen and (max-width:400px) {
            .success_twobox_cont {
                .success_img {
                    width: 90%;
                    height: 300px;
                }

                .success_content {
                    width: 90%;
                    height: 700px;
                }
            }

        }

    }
}