.jan-jagar-parent {
  height: 600px;
  width: 100%;
  background: url("../assets/Jan_Jagar_Sanvad.jpg");
  position: relative;
  display: flex;
  justify-content: center;

  .jan-jagar-overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #000000b4, #00000000);
    position: absolute;
  }

  .jan-jagar-headding1 {
    position: absolute;
    z-index: 2;
    color: white;
    padding-left: 30px;
    bottom: 20px;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
}

@media only screen and (max-width:500px) {
  .jan-jagar-parent {
    .jan-jagar-overlay {}

    .jan-jagar-headding1 {
      padding-left: 0px !important;
      font-size: 35px !important;
    }
  }
}