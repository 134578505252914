.factory-parent {
    height: 600px;
    width: 100%;
    background: url("../assets/Marathwada_Coach_Factory1.jpg");
    position: relative;
    display: flex;
    justify-content: center;


    .factory-overlay {
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, #000000b4, #00000000);
        position: absolute;
    }

    .factory-headding1 {
        position: absolute;
        z-index: 2;
        color: white;
        padding: 30px;
        bottom: 20px;
        font-size: 45px;
        font-weight: 700;
        text-align: center;
    }
}




// .header-bg-box {
//     height: 60px;
//     width: 100svw;
// }

.counter-parent1 {
    padding: 40px 0px;

    .akka-head {
        margin-bottom: 0px !important;
    }

    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        font-size: 30px;
        font-weight: 700;
    }


}

.ss {
    height: 500px;
    position: relative;

    //   background: linear-gradient(
    //     -90deg,
    //     rgba(0, 0, 0, 1) 0%,
    //     rgba(0, 0, 0, 0.43)
    //   );
    background: rgba(0, 0, 0, 0.695);

    // .overlay {
    //     position: absolute;
    //     top: 0px;
    //     left: 0px;
    //     height: 100%;
    //     width: 100%;
    //     z-index: -1;
    //     background: url("https://img.freepik.com/free-vector/indian-flag-theme-republic-day-watercolor-texture-decorative-background_1055-16780.jpg?t=st=1711088075~exp=1711091675~hmac=469eb1c0fcf1e8039f90abf3baa3b10440cfc2df88c26ade804e9a93fee9bba1&w=996");
    // }

    .ss-cont {
        display: flex;
        align-items: center;
        gap: 20px;

        .ss-left {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            // .img-box {
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     flex-direction: column;
            //     width: 80%;
            //     aspect-ratio: 5/4;
            //     padding: 20px;
            //     border-radius: 10px;
            //     background: url("../photos/coach_factory/Marathwada_coach_factory.webp");
            //     // outline: 7px solid var(--white);
            //     // box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
            // }
        }

        .ss-right {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 20px;

            h2 {
                line-height: 25px;
                color: var(--white);
            }

            .line {
                width: 50%;
                height: 3px;
                background: linear-gradient(90deg, var(--accent), transparent);
                margin: -15px 0px -10px 0px;
                border-radius: calc(var(--rad) / 2);
            }

            p {
                color: var(--white);
                line-height: 20px;
                font-weight: 200;
            }
        }
    }
}

.zig-zag {
    height: 500px;

    .zig-zag-cont {
        display: flex;
    }
}

@media only screen and (max-width: 1000px) {
    .ss {
        height: 100svh !important;
    }

    .ss-cont {
        flex-direction: column !important;

        .ss-left {
            width: clamp(500px, 60%, 80%) !important;

            .img-box {}
        }

        .ss-right {
            width: clamp(480px, 80%, 100%) !important;
            align-items: center !important;
        }
    }
}





@media only screen and (max-width: 500px) {
    .factory-parent {

        .factory-overlay {}

        .factory-headding1 {
            padding: 0px 10px !important;
            font-size: 35px !important;
        }
    }

    .counter-parent1 {
        padding: 40px 0px;

        .akka-head {
            margin-bottom: 0px !important;
        }

        h3 {
            font-size: 25px !important;
        }
    }




    .ss {
        height: 100svh !important;
    }

    .ss-cont {
        flex-direction: column !important;

        .ss-left {
            width: clamp(400px, 50%, 70%) !important;

            .img-box {}
        }

        .ss-right {
            width: clamp(400px, 60%, 70%) !important;
            align-items: center !important;
        }
    }
}

@media only screen and (max-width: 400px) {
    .ss {
        height: 100svh !important;
    }

    .ss-cont {
        flex-direction: column !important;

        .ss-left {
            width: clamp(300px, 50%, 70%) !important;

            .img-box {}
        }

        .ss-right {
            width: clamp(350px, 50%, 80%) !important;
            align-items: center !important;
            height: 700px !important;
        }
    }
}